<template>
  <div class="bannerVideo">
    <div class="video">
      <video
        :src="videoUrl"
        muted="muted"
        loop="loop"
        autoplay="autoplay"
        type="video/mp4"></video>
    </div>
    <div class="text-box">
      <slot></slot>
    </div>
  </div>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2022/12/22 17:57
 * @version 1.0
 * @description
 */
export default {
  props: {
    videoUrl: {
      type: String,
      default: process.env.VUE_APP_STATIC_FILE_URL + '/mp/video/growthAdviserVideo.mp4'
    },
  },
  name: 'BannerVideo'
}
</script>

<style scoped lang="scss">
.bannerVideo{
  position: relative;
  video{
    width: 100%;
    min-width: 1280px;
    object-fit: cover;
    pointer-events: none;
  }

  .text-box {
    position: absolute;
    top: 50%;
    width: 1180px;
    left: 50%;
    transform: translate(-50%, -50%);

    .title {
      font-size: 36px;
      font-family: Microsoft YaHei-Regular,Microsoft YaHei,serif;
      font-weight: 400;
      color: #333;
    }

    .desc {
      width: 558px;
      font-size: 18px;
      font-family: Microsoft YaHei-Regular,Microsoft YaHei,serif;
      font-weight: 400;
      color: #666;
      line-height: 25px;
      margin-top: 25px;
    }
  }
}

</style>
